

import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, Input, message } from 'antd';

const TRANSFER_TO_CORPAY = gql`
  mutation TransferToCorpay($amount: Float!) {
    transferToCorpay(amount: $amount)
  }
`;

const Transfers: React.FC = () => {
  const [amount, setAmount] = useState<string>('');
  const [transferToCorpay, { loading }] = useMutation(TRANSFER_TO_CORPAY);

  const handleTransfer = async () => {
    try {
      await transferToCorpay({
        variables: {
          amount: parseFloat(amount)
        }
      });
      setAmount('');
      message.success(`Successfully transferred $${amount} to Corpay`);
    } catch (error) {
      console.error('Transfer failed:', error);
      message.error('Failed to transfer to Corpay');
    }
  };

  return (
    <>
      <h1>Transfers</h1>
      <div>
        <Input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Enter amount"
          step="0.01"
          min={0}
          style={{ width: 200, marginRight: 8 }}
        />
        <Button
          onClick={handleTransfer}
          disabled={!amount || isNaN(parseFloat(amount)) || loading}
          loading={loading}
        >
          Transfer to Corpay
        </Button>
      </div>
    </>
  );
};

export default Transfers;