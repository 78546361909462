import React, { useState, useEffect, useCallback } from 'react';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import { Table, Spin, Button, message, Input, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';

const GET_UNPROCESSED_DOCS_QUERY = gql`
  query GetUnprocessedDocs($page: Int!, $limit: Int!, $search: String) {
    unprocessedDocs(page: $page, limit: $limit, search: $search) {
      items {
        id
        status
        filename
        isBill
        serviceAddress
        amountDue
        dueDate
        customerName
        firstName
        middleName
        lastName
        vendor
        statementDate
        accountNumber
        blobId
        description
      }
      count
    }
  }
`;

const GET_DOWNLOAD_URL_QUERY = gql`
  query GetDownloadDocumentByBlobId($blobId: String!) {
    downloadDocumentByBlobId(blobId: $blobId)
  }
`;

const RESET_UNPROCESSED_DOCS_STATUS_MUTATION = gql`
  mutation ResetUnprocessedDocStatus($ids: [Int!]!) {
    resetUnprocessedDocumentStatus(ids: $ids)
  }
`;

interface UnprocessedDoc {
  id: string;
  status: string;
  filename: string;
  isBill: boolean;
  blobId: string;
  accountNumber: string;
  amountDue: number;
  customerName: string | null;
  description: string;
  dueDate: string;
  firstName: string;
  lastName: string | null;
  middleName: string;
  serviceAddress: string | null;
  statementDate: string;
  vendor: string;
}

interface UnprocessedDocsData {
  unprocessedDocs: {
    items: UnprocessedDoc[];
    count: number;
  };
}

interface UnprocessedDocsVars {
  limit: number;
  page: number;
  search: string;
}

const UnprocessedDocsTable: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [unprocessedDocs, setUnprocessedDocs] = useState<UnprocessedDoc[]>([]);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [previewFileName, setPreviewFileName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [fetchUnprocessedDocs] = useLazyQuery<
    UnprocessedDocsData,
    UnprocessedDocsVars
  >(GET_UNPROCESSED_DOCS_QUERY);
  const [resetDocStatus] = useMutation(RESET_UNPROCESSED_DOCS_STATUS_MUTATION);
  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL_QUERY);

  const handlePreviewClick = async (blobId: string, filename: string) => {
    try {
      const { data } = await getDownloadUrl({
        variables: { blobId },
      });

      if (data?.downloadDocumentByBlobId) {
        setPreviewUrl(data.downloadDocumentByBlobId);
        setIsPreviewVisible(true);
        setPreviewFileName(filename);
      }
    } catch (error) {
      message.error('Error loading document preview');
    }
  };


  const columns: ColumnsType<UnprocessedDoc> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Filename",
      dataIndex: "filename",
      key: "filename",
      render: (filename, record) => (
        <Button
          type="link"
          onClick={() => handlePreviewClick(record.blobId, filename)}
        >
          {filename}
        </Button>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Statement Date",
      dataIndex: "statementDate",
      key: "statementDate"
    },
    {
      title: "Is Bill",
      dataIndex: "isBill",
      key: "isBill",
      render: (isBill: boolean) => (isBill ? "Yes" : "No"),
    },
    {
      title: "Account #",
      dataIndex: "accountNumber",
      key: "accountNumber"
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Service Address",
      dataIndex: "serviceAddress",
      key: "serviceAddress",
    },
    {
      title: "Provider",
      dataIndex: "vendor",
      key: "vendor",
    },
    {
      title: "Amount Due",
      dataIndex: "amountDue",
      key: "amountDue",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleUserClick(record.id)}>
          Reset Status
        </Button>
      ),
    },
  ];

  const loadUnprocessedDocs = useCallback(() => {
    setLoading(true);
    fetchUnprocessedDocs({
      variables: { limit: 10, page, search: debouncedSearch },
      fetchPolicy: "network-only", // Ensure a fresh query every time
      onCompleted: (data) => {
        const processedItems = data.unprocessedDocs.items.map(item => {
          if (item.firstName || item.middleName || item.lastName) {
            const fullName = [item.firstName, item.middleName, item.lastName]
              .filter(Boolean)
              .join(' ');
            return {
              ...item,
              customerName: fullName
            };
          }
          return item;
        });
        setUnprocessedDocs(processedItems);
        setTotal(data.unprocessedDocs.count);
        setLoading(false);
      },
      onError: () => {
        message.error("Error loading unprocessed documents");
        setLoading(false);
      },
    });
  }, [fetchUnprocessedDocs, page, debouncedSearch]);

  useEffect(() => {
    loadUnprocessedDocs();
  }, [loadUnprocessedDocs]);

  useEffect(() => {
    const handler = setTimeout(() => {
      const cleanedSearch = search.replace(/\s+/g, ''); // Remove spaces
      if (cleanedSearch.length >= 3 || cleanedSearch.length === 0) {
        setDebouncedSearch(search);
      }
    }, 300); // 300ms delay

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const handleUserClick = async (unprocessedDocId: string) => {
    try {
      await resetDocStatus({ variables: { ids: [+unprocessedDocId] } });
      message.success(`Status reset for document ${unprocessedDocId}`);
      await loadUnprocessedDocs(); // Reload the data after mutation
    } catch (error) {
      message.error("Error resetting document status");
    }
  };

  const handleTableChange = (pagination: any) => {
    setPage(pagination.current);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleRefreshClick = () => {
    loadUnprocessedDocs(); // Manually trigger data refresh
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <h1 style={{ margin: 0 }}>Processed Docs</h1>
        <Button type="default" onClick={handleRefreshClick}>
          Refresh
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "left",
          marginBottom: 2,
        }}
      >
        <Input
          placeholder="Search..."
          value={search}
          onChange={handleSearchChange}
          style={{ width: 200, marginRight: 16 }}
        />
      </div>

      <Modal
        title={previewFileName}
        open={isPreviewVisible}
        onCancel={() => setIsPreviewVisible(false)}
        width="80%"
        footer={null}
      >
        <iframe
          src={previewUrl}
          style={{ width: '100%', height: '80vh' }}
          title="Document Preview"
        />
      </Modal>

      <Spin spinning={loading}>
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <Table
            columns={columns}
            dataSource={unprocessedDocs}
            pagination={{ current: page, pageSize: 10, total }}
            onChange={handleTableChange}
            rowKey="id"
            scroll={{ x: 'max-content' }}
          />
        </div>
      </Spin>
    </>
  );
};

export default UnprocessedDocsTable;
