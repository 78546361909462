import React from "react";
import { Form, Checkbox, Typography, Card } from "antd";
import { Link } from "react-router-dom";
const { Title } = Typography;

// Add interface for the component props
interface TermsFormProps {
  bankAccounts: Array<{
    bankName: string;
    accountNickname?: string;
    accountNumber?: string;
  }>;
  onTermsAccepted: (accepted: boolean) => void;
}

const TermsForm: React.FC<TermsFormProps> = ({ bankAccounts, onTermsAccepted }) => {
  const handleCheckboxChange = (e: any) => {
    onTermsAccepted(e.target.checked);
  };

  return (
    <>
      <Title level={4} style={{ marginTop: 0 }}>
        Term Acknowledgement
      </Title>
      {bankAccounts && (
        <>
          <p>
            Please review the following bank accounts that your SilverBills Account Manager will use
            to facilitate bill payments:
          </p>
          <ul style={{
            textAlign: 'left',
            listStyleType: 'none',
            padding: 0,
            maxHeight: '250px',
            overflowY: 'auto',
            border: '1px solid #eaeaea',
            borderRadius: '4px',
            backgroundColor: '#ffffff'
          }}>
            {bankAccounts.map(
              (account: any, index: number) => (
                <li key={index} style={{
                  padding: '5px 0',
                  borderBottom: '1px solid #eaeaea',
                  marginLeft: '5px'
                }}>
                  {account.bankName}{account.accountNumber ? ` - ****${account.accountNumber.slice(-4)}` : ""}
                </li>
              )
            )}
          </ul>
        </>
      )
      }
      <Card style={{ backgroundColor: 'white', marginBottom: '2rem' }}>
        <Form.Item>
          <p style={{ marginTop: 0 }}>
            I authorize future payments initiated by SilverBills to be processed through the Dwolla payment system using the accounts listed above. To cancel this authorization, I will contact my account manager and submit a cancellation request.
          </p>
          <p style={{ marginTop: 0 }}>
            By agreeing and completing the signup process, I acknowledge that SilverBills will begin charging my monthly subscription fee of $200.
          </p>
          <Checkbox onChange={handleCheckboxChange}></Checkbox>
          {" "}By checking this box, you agree to SilverBills'{" "}
          <Link
            to={process.env.PUBLIC_URL + "/documents/service_agreement.pdf"}
            target="_blank"
            style={{ color: '#1890ff', textDecoration: 'underline' }}
          >
            Service Agreement
          </Link>
          ,{" "}
          <Link
            to="https://silverbills.com/terms-of-use/"
            target="_blank"
            style={{ color: '#1890ff', textDecoration: 'underline' }}
          >
            Terms of Use
          </Link>
          , and {" "}
          <Link
            to="https://silverbills.com/privacy-policy/"
            target="_blank"
            style={{ color: '#1890ff', textDecoration: 'underline' }}
          >
            Privacy Policy
          </Link>
          , as well as our partner Dwolla's{" "}
          <Link
            to="https://www.dwolla.com/legal/dwolla-account-terms-of-service"
            target="_blank"
            style={{ color: '#1890ff', textDecoration: 'underline' }}
          >
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link
            to="https://www.dwolla.com/legal/privacy"
            target="_blank"
            style={{ color: '#1890ff', textDecoration: 'underline' }}
          >
            Privacy Policy
          </Link>
          .
        </Form.Item>
      </Card >
    </ >
  );
};

export default TermsForm;
