// Signup.tsx

import { useState } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Typography,
} from 'antd';
import { useMutation, gql } from '@apollo/client';
import SectionIndicator from '../../components/SectionIndicator';

// Child components
import PersonalInformation from './PersonalInformation';
import FinancialInformation from './FinancialInformation';
import SignupTerms from './SignupTerms';  // Add this import

const { Title } = Typography;

// --------------------------------------------
// 1. Define Your GraphQL Mutation
// --------------------------------------------
const REGISTER_MUTATION = gql`
  mutation Register(
    $firstName: String!
    $lastName: String!
    $email: String!
    $addressLine1: String!
    $addressLine2: String
    $state: String!
    $city: String!
    $zip: String!
    $phoneNumber: String!
    $externalBankAccounts: [ExternalBankAccountInput!]!
    $contacts: [ContactInput!]
    $acceptedTerms: Boolean!
  ) {
    register(
      firstName: $firstName
      lastName: $lastName
      email: $email
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      state: $state
      city: $city
      zip: $zip
      phoneNumber: $phoneNumber
      externalBankAccounts: $externalBankAccounts,
      contacts: $contacts,
      acceptedTerms: $acceptedTerms
    )
  }
`;

// --------------------------------------------
// 2. The Signup Component
// --------------------------------------------
const Signup = () => {
  const [form] = Form.useForm();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const [register, { loading: mutationLoading, error: mutationError }] =
    useMutation(REGISTER_MUTATION);

  // Step Titles for the Reusable Indicator
  const stepTitles = [
    'Personal Information',
    'Financial Information',
    'Terms Acknowledgement',
  ];

  // --------------------------------------------
  // Submission Handler
  // --------------------------------------------
  const onFinish = async () => {
    const data = form.getFieldsValue(true);
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      bankAccounts,
      contacts
    } = data;

    // Transform bankAccounts into shape required by the API
    const externalBankAccounts = bankAccounts.map((account: any) => ({
      nameOnBankAccount: account.accountName,
      bankName: account.bankName,
      type: account.accountType.toUpperCase(),
      routingNumber: account.routingNumber,
      accountNumber: account.accountNumber,
      isPrimary: account.primary,
    }));

    try {
      const { data } = await register({
        variables: {
          firstName,
          lastName,
          email,
          phoneNumber,
          addressLine1,
          addressLine2,
          city,
          state,
          zip: zipCode,
          externalBankAccounts,
          contacts,
          acceptedTerms: true
        },
      });
      setSubmitted(true);
    } catch (err) {
      console.error('GraphQL Error:', err);
    }
  };

  // --------------------------------------------
  // "Primary" Checkbox Handler
  // --------------------------------------------
  const onPrimaryChange = (checked: boolean, index: number) => {
    if (checked) {
      const values = form.getFieldsValue();
      const updatedBankAccounts = values.bankAccounts.map(
        (account: any, idx: number) => ({
          ...account,
          primary: idx === index,
        })
      );
      form.setFieldsValue({ bankAccounts: updatedBankAccounts });
    }
  };

  // --------------------------------------------
  // Step Navigation Handlers
  // --------------------------------------------
  const onNext = async () => {
    try {
      if (currentStep === 0) {
        // Validate Personal Info fields only
        await form.validateFields([
          'firstName',
          'lastName',
          'email',
          'phoneNumber',
          'addressLine1',
          'city',
          'state',
          'zipCode',
          'contacts'
        ]);

        const contactsValue = form.getFieldValue('contacts') || [];
        for (let i = 0; i < contactsValue.length; i += 1) {
          await form.validateFields([
            ['contacts', i, 'firstName'],
            ['contacts', i, 'lastName'],
            ['contacts', i, 'email'],
            ['contacts', i, 'phoneNumber'],
            ['contacts', i, 'type'],
          ]);
        }
      } else if (currentStep === 1) {
        // Validate Financial Info fields only
        await form.validateFields([
          'bankAccounts',
        ]);

        const bankAccounts = form.getFieldValue('bankAccounts');
        for (let i = 0; i < bankAccounts.length; i += 1) {
          await form.validateFields([
            ['bankAccounts', i, 'accountName'],
            ['bankAccounts', i, 'bankName'],
            ['bankAccounts', i, 'accountType'],
            ['bankAccounts', i, 'routingNumber'],
            ['bankAccounts', i, 'accountNumber'],
          ]);
        }
      }
      setCurrentStep(currentStep + 1);
    } catch (error) {
      console.log('Validation error:', error);
    }
  };

  const onPrev = () => {
    if (currentStep === 2) {
      // Reset terms acceptance when moving back from terms page
      setTermsAccepted(false);
    }
    setCurrentStep(currentStep - 1);
  };

  // --------------------------------------------
  // Choose which step content to render
  // --------------------------------------------
  // Update renderStepContent to include the third step
  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return <PersonalInformation form={form} />;
      case 1:
        return <FinancialInformation onPrimaryChange={onPrimaryChange} />;
      case 2:
        return <SignupTerms
          bankAccounts={form.getFieldValue('bankAccounts').map((account: any) => ({
            bankName: account.bankName,
            accountNickname: account.accountName,
            accountNumber: account.accountNumber
          }))}
          onTermsAccepted={setTermsAccepted}
        />;
      default:
        return <p>Some future step or fallback content here.</p>;
    }
  };

  // Update the submit button to be disabled when terms are not accepted
  return (
    <Col xs={24} sm={20} md={16} lg={12}>
      <Title level={3} style={{ textAlign: 'center', marginBottom: '2rem' }}>
        Sign Up
      </Title>

      {!submitted && <SectionIndicator currentStep={currentStep} steps={stepTitles} />}

      <Form
        form={form}
        name="signUpForm"
        layout="vertical"
        preserve={true}
        initialValues={{
          bankAccounts: [
            {
              accountName: '',
              bankName: '',
              accountType: 'checking',
              routingNumber: '',
              accountNumber: '',
              primary: true,
            },
          ],
        }}
        onFinish={onFinish}
        style={{ background: '#fff', padding: '2rem', borderRadius: '4px', marginBottom: '2rem' }}
      >
        {submitted ? (
          <div>
            <Title level={4} style={{ marginTop: 0 }}>
              Confirmation
            </Title>
            <p style={{ fontSize: '1.2em' }}>
              Thank you for completing your registration and signing the Terms & Conditions and Agreement.
              Your Account Manager is being assigned and will review your application.
              They will reach out in 2-4 business days to schedule your onboarding call.
              If you have any questions or need assistance, please contact us at info@silverbills.com or 800-825-1924.
              We look forward to assisting you!
            </p>
          </div>
        ) : (
          <>
            {/* Step-specific content */}
            {renderStepContent()}

            <Form.Item style={{ marginBottom: 0 }}>
              <Row justify="space-between">
                <Col>
                  {currentStep > 0 && (
                    <Button onClick={onPrev}>Previous</Button>
                  )}
                </Col>

                <Col>
                  {currentStep === 0 && (
                    <Button type="primary" onClick={onNext}>
                      Next
                    </Button>
                  )}
                  {currentStep === 1 && (
                    <Button
                      type="primary"
                      onClick={onNext}
                    >
                      Next
                    </Button>
                  )}
                  {currentStep === 2 && (
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={mutationLoading}
                      disabled={currentStep === 2 && !termsAccepted}
                    >
                      Submit
                    </Button>
                  )}
                </Col>
              </Row>
            </Form.Item>

            {/* Show any GraphQL error */}
            {mutationError && (
              <p style={{ color: 'red' }}>Error: {mutationError.message}</p>
            )}
          </>
        )}
      </Form>
    </Col>

  );
};

export default Signup;
